import { Link } from "react-router-dom";
import { markedApplications } from "../applications.ts";


export default function Root() {
    return (
            <ul>
                {markedApplications.map((app) => (
                    <li key={app.name}>
                        <Link to={`/apps/${app.name}`}>{app.name}</Link>
                    </li>
                ))}
            </ul>
    );
}
