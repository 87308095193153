
export class Config {
    readonly tenantId: string;
    readonly clientId: string;
    readonly subscriptionId: string;
    readonly logWorkspaceId: string;
    readonly environment: string;

    constructor(tenantId: string,
                clientId: string,
                subscriptionId: string,
                logWorkspaceId: string,
                environment: string) {
        this.clientId = clientId;
        this.tenantId = tenantId;
        this.subscriptionId = subscriptionId;
        this.logWorkspaceId = logWorkspaceId;
        this.environment = environment;
    }

    isDev() {
        return this.environment === 'development';
    }
}


const config = new Config(
    process.env.APP_TENANT_ID!,
    process.env.APP_CLIENT_ID!,
    process.env.APP_SUBSCRIPTION_ID!,
    process.env.APP_WORKSPACE_ID!,
    process.env.APP_ENVIRONMENT!,
);
export default config