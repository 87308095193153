import config from "./Config.ts";

export function resourceGroup(appName: string) {
    const env = config.isDev() ? 'dev' : 'prod';
    return `${env}-marked-${appName}`;
}

export class App {
    readonly name: string;
    readonly hostname: string;
    readonly logsUrl: string;


    constructor(name: string,
                hostname: string,
                logsUrl: string) {
        this.name = name;
        this.hostname = hostname;
        this.logsUrl = logsUrl
    }
}

const markedApplicationsNames = [
    'bp-api',
    'bisnode-api',
    'elhub-api',
    'hark-partner-api',
    'mittnte-adminapi',
    'mittnte-api',
    'nordpool-dayahead-import',
    'send-api',
    'strombestilling-api',
    'uc-meteringvalues',
    'uc-eventconsumer'
];

export const markedApplications = markedApplicationsNames
    .map(name => new App(
        name,
        `${name}.marked.azure.nte.${config.isDev() ? 'dev' : 'no'}`,
        LAWUrl(config.subscriptionId, resourceGroup(name))
    ));

function LAWUrl(subscriptionId: string, resourceGroup: string): string {
    return `https://portal.azure.com/#@nteholding.onmicrosoft.com/resource/subscriptions/${subscriptionId}/resourceGroups/${resourceGroup}/providers/Microsoft.Web/sites/${resourceGroup}-app/analytics`
}
