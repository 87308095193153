import { Routes, Route, Outlet, Link } from "react-router-dom";
import Root from "./routes/Root.tsx";
import Application from "./routes/Application.tsx";
import {ApplicationLogs, ErrorIntervalLogs, IntervalLogs, TraceIdLogs} from "./routes/ApplicationLogs.tsx";
import './App.css';

export default function App() {
    return (
        <div>
            <h1>Applikasjoner</h1>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Root />} />
                    <Route path='/apps/:appId' element={<Application />} />
                    <Route path='/apps/:appId/logs' element={<ApplicationLogs />} />
                    <Route path='/apps/:appId/logs/interval/:query' element={<IntervalLogs />} />
                    <Route path='/apps/:appId/logs/errors/interval/:query' element={<ErrorIntervalLogs />} />
                    <Route path='/logs/traceId/:query' element={<TraceIdLogs />} />
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </div>
    );
}

function Layout() {
    return (
        <div>
            <nav>
                <ul>
                    <li>
                        <Link to="/">Applikasjoner</Link>
                    </li>
                </ul>
            </nav>

            <hr />
            <Outlet />
        </div>
    );
}

function NoMatch() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}