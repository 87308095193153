import { Link, useParams } from "react-router-dom";
import { markedApplications } from "../applications";
import { useFetch } from "../useFetch.ts";

export default function Application() {
    const { appId } = useParams() as {appId: string};
    const app = markedApplications.find(a => a.name == appId);
    if(!app) {
        return (<div>Not found</div>)
    } else {
        const {name, hostname} = app;
        return (<article>
            <h2><a href={`https://${hostname}`}>{name}</a></h2>
            <HealthCheck hostname={hostname}/>
            <VersionInfo hostname={hostname}/>
            <Link to={`/apps/${name}/logs`}>Logger</Link>
        </article>)
    }
}

interface HealthCheckResponse {
    status: string,
    checks: [{name: string, status: string}]
}
function HealthCheck(props: { hostname: string }) {
    const { hostname } = props;
    const { data, loading, error } = useFetch<HealthCheckResponse>(hostname, '/q/health');
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div>
            Helsesjekk: {data?.status}
        </div>
    )
}

interface GitCommit {
    id: string;
    time: string;
}

interface Git {
    branch: string;
    commit: GitCommit;
}

interface Java {
    version: string;
}

interface OS {
    name: string;
    version: string;
    arch: string;
}

interface Build {
    group: string;
    artifact: string;
    version: string;
    time: string;
    quarkusVersion: string;
    enabled: string;
}

interface VersionInfoResponse {
    git: Git;
    java: Java;
    os: OS;
    build: Build;
}
function VersionInfo(props: { hostname: string }) {
    const { hostname } = props;
    const { data, loading, error } = useFetch<VersionInfoResponse>(hostname, '/q/info');
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div>
            Bygg: {`${data?.build.version} (${data?.git.branch})`}
        </div>
    )
}